import { AudioDeviceInfo, Call, DeviceManager, DiagnosticQuality, Features, LocalVideoStream, MediaDiagnosticChangedEventArgs, MediaStatsReportSummary, NetworkDiagnosticChangedEventArgs, TeamsMeetingLinkLocator } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential, CommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {
  CallAndChatLocator,
  CallWithChatComposite,
  //useAzureCommunicationCallWithChatAdapter,
  CallWithChatCompositeOptions,
  createStatefulCallClient,
  createStatefulChatClient,
  createAzureCommunicationCallWithChatAdapterFromClients,
  CallWithChatAdapter,
  AdapterError,
} from '@azure/communication-react';
import { Theme, PartialTheme, Spinner, initializeIcons, Stack } from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import MetricsUtils from 'Utils/MetricsUtils';
import { MeetingActivityStatus, MeetingStatus, TelehealthClient } from 'Utils/TelehealthClient';
import errorMessages from 'constants/errorMessages';
import "./CompositeGroupCall.css"

initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20210407.001/assets/icons/');

export type CompositeGroupCallProps = {
  // Props needed for the construction of the CallWithChatAdapter
  userTokenCode?:string;
  userId: CommunicationUserIdentifier;
  token: string;
  displayName: string;
  endpointUrl: string;
  /**
   * For CallWithChat you need to provide either a teams meeting locator or a CallAndChat locator
   * for the composite
   *
   * CallAndChatLocator: This locator is comprised of a groupId call locator and a chat thread
   * threadId for the session. See documentation on the {@link CallAndChatLocator} to see types of calls supported.
   * {callLocator: ..., threadId: ...}
   *
   * TeamsMeetingLinkLocator: this is a special locator comprised of a Teams meeting link
   * {meetingLink: ...}
   */
  locator: TeamsMeetingLinkLocator;

  // Props to customize the CallWithChatComposite experience
  fluentTheme?: PartialTheme | Theme;
  rtl?: boolean;
  compositeOptions?: CallWithChatCompositeOptions;
  callInvitationURL?: string;
  formFactor?: 'desktop' | 'mobile';

  defaultCameraLabel?: string;
  defaultMicrophoneLabel?: string;
  defaultSpeakerLabel?: string;
  telehealthClient: TelehealthClient;
  callIsReadyToStart: boolean;
  onCallComplete: () => void;
  onTrackEvent: (name: string, eventMessage: string, properties?: any) => {};
  onGlobalError: (strErr: string, isRecoverable: boolean, Exception: any) => {};
  logo: {
    url: string;
    alt: string;
  }
  autoJoin: boolean;
};

const getChatThreadFromTeamsLink = (teamsMeetingLink: string): string => {
  // Get the threadId from the url - this also contains the call locator ID that will be removed in the threadId.split
  let threadId = teamsMeetingLink.replace('https://teams.microsoft.com/l/meetup-join/', '');
  // Unescape characters that applications like Outlook encode when creating joinable links
  threadId = decodeURIComponent(threadId);
  // Extract just the chat guid from the link, stripping away the call locator ID
  threadId = threadId.split(/^(.*?@thread\.v2)/gm)[1];
  if (!threadId || threadId.length === 0) {
    throw new Error('Could not get chat thread from teams link');
  }

  return threadId;
};

let autoCameraOn = false;
let callStarted = false;
let callEnded = false;
let processingPermission =false;
let cameraPermission ='';
let micPermission='';
const CompositeGroupCall = (props: CompositeGroupCallProps): JSX.Element => {
  // Construct a credential for the user with the token retrieved from your server. This credential
  // must be memoized to ensure useAzureCommunicationCallWithChatAdapter is not retriggered on every render pass
  const credential = useMemo(() => new AzureCommunicationTokenCredential(props.token), [props.token]);
  const [adapter, setAdapter] = useState<CallWithChatAdapter | null>(null);
  const [chatUsed, setChatUsed] = useState(false);
  const [showHelpText, setShowHelpText] = useState(false);
  const [showSurveyTitle, setshowSurveyTitle] = useState(false);
  

  const toggleHelpText = () => {
    if(!showHelpText){
      props.onTrackEvent('clickedHelp', "User clicked help button.");
      
      if((window as any).acsDm){
        ((window as any).acsDm as DeviceManager).askDevicePermission({audio:true, video:true});
      }
      props.telehealthClient.postHardwareTrouble(); //fire and forget
    }
    setShowHelpText(!showHelpText)
  }



  async function createAdapter(args: {
    userId: CommunicationUserIdentifier;
    displayName: string;
    credential: CommunicationTokenCredential;
    endpoint: string;
    locator: CallAndChatLocator;
    teamsLocator: TeamsMeetingLinkLocator;
  }): Promise<CallWithChatAdapter> {

    if ((window as any).acsAdapter) {
      return (window as any).acsAdapter;
    }

    const { userId, displayName, credential, endpoint, locator, teamsLocator } = args;
    const callClient = createStatefulCallClient({ userId },{callClientOptions:{
      diagnostics:{
        tags:["CSN:" +props.userTokenCode]
      }
    }});
    const callAgent = await callClient.createCallAgent(credential, { displayName: displayName });
    let dm = await callClient.getDeviceManager();
    (window as any).acsDm = dm;

    const chatClient = createStatefulChatClient({ userId, credential, endpoint, displayName });
    chatClient.startRealtimeNotifications();
    const chatThreadClient = chatClient.getChatThreadClient(locator.chatThreadId);
    const newAdapter = await createAzureCommunicationCallWithChatAdapterFromClients({
      callClient,
      callAgent,
      callLocator: teamsLocator,
      chatClient,
      chatThreadClient,
    });

    let noShowTimer: number = -1;
    let callEndTimer: number = -1;


    newAdapter.on('callParticipantsJoined', async (s) => {
      try {
        if (noShowTimer > -1) {
          window.clearTimeout(noShowTimer);
          const callId = callAgent.calls[0]?.id;
          const callerId = callAgent.calls[0]?.info.participantId;
          // await props.telehealthClient.postMeetingStatus({ Status: MeetingStatus.PatientJoined });       
          props.onTrackEvent('Call Started, patient admitted to meeting by provider','Call Started,  patient admitted to meeting by provider' , {callId, participantId: callerId});
          props.telehealthClient.postMeetingStatus({ Status: "MeetingStatus:" + MeetingStatus.PatientJoined + "|MeetingActivityStatus:"+ MeetingActivityStatus.ProviderAdmittedPatientToCall});

          
          
        
        }
        if (callEndTimer > -1) {
          window.clearTimeout(callEndTimer);
        }
      } catch { }
      return true;
    });

    newAdapter.on('callParticipantsLeft', async (s) => {
      try {
        if (callAgent.calls[0].remoteParticipants.length == 0) {
          callEndTimer = window.setTimeout(() => {
            newAdapter.leaveCall();
          }, 1000 * 60 * 20)
        }
      } catch { }
      return true;
    });

    newAdapter.on('callEnded', async (s) => {
      try {


        callEnded = true;
        
        if (combinedMetrics) {
          console.log("Posting Full Combined Diagnostics");
          await props.telehealthClient.postMeetingDiagnostics(combinedMetrics);
          console.log(combinedMetrics);
        }
        
        let callEndReason:any =  callAgent.calls[0]?.callEndReason
        let callEndReasonCode =  callEndReason?.code
        let callEndReasonsubCode =  callEndReason?.subCode
        let callEndReasonresultCategories = callEndReason?.resultCategories
        let callID = s.callId;      
        
        if (callEndTimer > -1) {
          window.clearTimeout(callEndTimer);
        }
        await props.telehealthClient.postMeetingStatus({ Status: "MeetingStatus:" + MeetingStatus.CallEnded + "|MeetingActivityStatus:"+ MeetingActivityStatus.CallEnded});        
        await props.onTrackEvent('Call Ended',callEndReason.message ,{callEndReasonCode,callEndReasonsubCode,callID,callEndReasonresultCategories});        
        adapter?.dispose();
      } catch { }
      return true;
    });

    

    newAdapter.on('callError', async (e:AdapterError) => {
      console.log('posting failure');
      console.log(e);
      console.log(e.target);
      console.log(e.innerError);
      console.log(e.cause);
      await props.telehealthClient.postMeetingStatus({ Status: MeetingStatus.CallFailed });
      // props.onGlobalError(errorMessages.CallFailedError + e.message, true);

      if(e.target =="Call.stopVideo" || e.target =="Call.startVideo" || e.target =="Call.startScreenSharing"){
        //log error but not end the call
        props.onTrackEvent('Call Error', e.target, {e});
   
      }
      else{
        let postMessage = '';
        try{
          const sections = e.message.split(': ');
          if(sections.length == 2){
            postMessage = '\n\n Error details: ' + sections[1];
          }
        }catch{} //eat the error if we can't process the message.     
        props.onGlobalError(errorMessages.GenericFetchError + postMessage, true, e);
      }
      
    });

    newAdapter.on('messageSent', async (m) => {
      try {
        console.log('posting chatUsed');
        if (!chatUsed) {
          await props.telehealthClient.postChatUtilized();
          props.onTrackEvent('chatUsed', 'Chat utilized for first time on call.');
          setChatUsed(true);
        }
        console.log('posted');
      } catch { }
    });


    newAdapter.on('messageReceived', async (m) => {
      try {
        console.log('received chat message');
        console.log(m);
        if (m.message.content?.message === 'Our call has ended. Please hangup now.') {
          newAdapter.leaveCall();
        }
        console.log('posted');
      } catch { }
    });

    dm.on('selectedMicrophoneChanged', async () => {

      let microphonePermissionState: PermissionState = (
        await navigator.permissions.query({ name: 'microphone' as PermissionName })
      ).state;

      props.onTrackEvent('selectedMicrophoneChanged', 'Selected microphone changed to: ' + dm.selectedMicrophone?.name ?? "Unknown",{microphonePermissionState});      
    });

    dm.on('selectedSpeakerChanged', () => {
      props.onTrackEvent('selectedSpeakerChanged','Selected speaker changed to: ' + dm.selectedSpeaker?.name ?? "Unknown");
    });

    dm.on('videoDevicesUpdated', async (e)=>{
      let cameraPermissionState: PermissionState = (
        await navigator.permissions.query({ name: 'camera' as PermissionName })
      ).state;

      props.onTrackEvent('videoDevicesUpdated','video devices updated. \nAdded:' + e.added.map(e => e.name ?? "unknown" + ": " + e.deviceType ?? 'unknown').join(', ') + '\nRemoved: '  + e.removed.map(e => e.name ?? "unknown" + ": " + e.deviceType ?? 'unknown').join(', '), {cameraPermissionState});
    });

    newAdapter.on('isMutedChanged', (e)=>{


      props.onTrackEvent('isMutedChanged','Mute state changed: ' + (e.isMuted ? "Muted" : "Unmuted"));
    });

    newAdapter.on('chatError', (e)=>{
      props.onTrackEvent('chatError', e.message);
    });

    let combinedMetrics: MediaStatsReportSummary | null = null;

    newAdapter.onStateChange(async s => {      
      let state:any = s;
      
      if(s.page == 'leftCall'){
        setshowSurveyTitle(true);
      }
      //Enabling video only once, when permissions are granted and video is not enabled
      if ( !autoCameraOn && state.devices.cameras.length >= 1 && !callAgent?.calls[0]?.isLocalVideoStarted && !callEnded) {
        autoCameraOn = true;        
        autoCameraOn = await EnableVideo(dm,callAgent?.calls[0],newAdapter);
      }

      if(!processingPermission && !callEnded){  
  
        let microphonePermissionState: PermissionState = (
          await navigator.permissions.query({ name: 'microphone' as PermissionName })
        ).state;
  
      
        let cameraPermissionState: PermissionState = (
          await navigator.permissions.query({ name: 'camera' as PermissionName })
        ).state;

        if (microphonePermissionState != micPermission) {          
          let message = 'Microphone Permissions ' + (micPermission ?  "updated to ":"are ") + (microphonePermissionState == 'prompt' ? 'not granted' : microphonePermissionState);          
          props.onTrackEvent(message, microphonePermissionState);
          micPermission = microphonePermissionState;
          if(microphonePermissionState == 'granted' || microphonePermissionState == 'denied'){
            props.telehealthClient.postMeetingStatus({ Status: "MeetingActivityStatus:"+ (microphonePermissionState == 'granted'?  MeetingActivityStatus.MicPermissionsGranted:MeetingActivityStatus.MicPermissionsDenied)});
          }
        }
        if (cameraPermissionState != cameraPermission) {          
          let message = 'Camera Permissions ' + (cameraPermission ? "updated to ": "are ") + (cameraPermissionState == 'prompt' ? 'not granted' : cameraPermissionState);
          props.onTrackEvent(message, cameraPermissionState);          
          cameraPermission = cameraPermissionState;
        }

        processingPermission =false;
      }


      if (callAgent.calls.length > 0 && !callStarted) {
        autoCameraOn = !callAgent?.calls[0]?.isLocalVideoStarted;
        callStarted = true;        
        const localVideo = callAgent.calls[0]?.isLocalVideoStarted ? 'with video' : 'without video';
        const cameraName = callAgent.calls[0]?.localVideoStreams[0]?.source?.name;
        const callId = callAgent.calls[0]?.id;
        const callerId = callAgent.calls[0]?.info.participantId;
        let cameraPermissionState: PermissionState = (
          await navigator.permissions.query({ name: 'camera' as PermissionName })
        ).state;
        let microphonePermissionState: PermissionState = (
          await navigator.permissions.query({ name: 'microphone' as PermissionName })
        ).state;      
        
        props.onTrackEvent('Patient joined and waiting in lobby','Patient joined and waiting in lobby to be admitted ' + localVideo + ' with Camera: ' + (cameraName ?? 'unknown') + '. Selected Microphone: ' + (dm.selectedMicrophone?.name ?? 'unknown') + 'Selected Speaker: ' + (dm.selectedSpeaker?.name ?? 'unknown') + '.', {callId, participantId: callerId,cameraPermissionState,microphonePermissionState});
        props.telehealthClient.postMeetingStatus({ Status: "ACSCallID:" + callId + "|" + "ACSParticipantID:" + callerId + "|MeetingStatus:"+ MeetingStatus.PatientWaitingInLobby+ "|MeetingActivityStatus:"+ MeetingActivityStatus.PatientWaitingInLobby});
        noShowTimer = window.setTimeout(() => {
          props.onGlobalError(errorMessages.TimeOutError, false, null);
        }, 1000 * 60 * 30);
        
        if(localVideo == 'without video' && cameraPermissionState=="granted"){
        const videoDeviceInfo = await dm.getCameras();
        const preferredCamer = videoDeviceInfo[0];
        if(preferredCamer){
          const localVideStream = new LocalVideoStream(preferredCamer);
          callAgent.calls[0].startVideo(localVideStream);          
        }
      }
      if(callAgent.calls[0]?.isMuted){        
        callAgent.calls[0]?.unmute();
      }

        callAgent.calls[0].setConstraints({
          video: {
            send: {
              frameHeight: {
                max: 1080
              },
              frameRate: {
                max: 30
              }
            }
          }
        });

        const collector = callAgent.calls[0].feature(Features.MediaStats).createCollector();
        callAgent.calls[0].on('isLocalVideoStartedChanged', ()=>{
          props.onTrackEvent("isLocalVideoStartedChanged", callAgent.calls[0]?.isLocalVideoStarted ? 'Video Started' : 'Video Stopped');
        });

        
        const metricsUtil = new MetricsUtils();
        collector.on('summaryReported', async (summary) => {
          //during the call, we post the most recent summary report for a live view of metrics
          await props.telehealthClient.postMeetingDiagnostics(summary)
          console.log('Posting metrics');
          console.log(summary);
          console.log('Combined metrics:');
          combinedMetrics = metricsUtil.mergeMetricSummary(combinedMetrics, summary);
          console.log(combinedMetrics);
        });

        const userFacingDiagnostics = callAgent.calls[0].feature(Features.UserFacingDiagnostics);
        const diagnosticsChangedListener = (diagnosticInfo: NetworkDiagnosticChangedEventArgs | MediaDiagnosticChangedEventArgs) => {
          if (diagnosticInfo.valueType === 'DiagnosticQuality') {
            if (diagnosticInfo.value === DiagnosticQuality.Bad) {
              props.onTrackEvent("qualityBad", "User facing diagnostics report bad quality.");
     
            } else if (diagnosticInfo.value === DiagnosticQuality.Poor) {
              props.onTrackEvent("qualityFair", "User facing diagnostics report fair or poor (but not bad) quality.");
            } else{
              props.onTrackEvent("qualityGood", "User facing diagnostics report good quality.");
            }
     
          } else if (diagnosticInfo.valueType === 'DiagnosticFlag') {
            if (diagnosticInfo.value === true) {
              props.onTrackEvent(diagnosticInfo.diagnostic, "From User Facing Diagnostics");
            }
          }
        };
        userFacingDiagnostics.network.on('diagnosticChanged', diagnosticsChangedListener);
        userFacingDiagnostics.media.on('diagnosticChanged', diagnosticsChangedListener);

      }

      // if (s.call?.state == 'Disconnected' && !callEnded) {
      //   callEnded = true;
        
      //   if (combinedMetrics) {

      //     console.log("Posting Full Combined Diagnostics");
      //     await props.telehealthClient.postMeetingDiagnostics(combinedMetrics);
      //     console.log(combinedMetrics);
      //   }
      // }

    });

    newAdapter.unmute();

  //  EnableVideo(newAdapter);
    
    if (props.autoJoin) {
      newAdapter.joinCall({microphoneOn: true, cameraOn: true });
    }
    
    return newAdapter;
  }

  //return if video got enabled
  async function EnableVideo(dm:DeviceManager,call:Call,adapter:CallWithChatAdapter):Promise<boolean>{

    let isComplete = false;
    let cameraPermissionState: PermissionState = (
      await navigator.permissions.query({ name: 'camera' as PermissionName })
    ).state;

    if(cameraPermissionState == "granted"){
      // if(call){
      //   const cameras = await dm.getCameras();
      //   const camera = cameras[0]
      //   const localVideoStream = new LocalVideoStream(camera);
      //   await call?.startVideo(localVideoStream);
      // }
      // else{
         adapter.startCamera();
         console.log("starting camera");
      // }
     
      isComplete = true;
    }
    
    return isComplete;
  }

  async function setDevices() {

    if ((window as any).acsDm) {
      const dm = ((window as any).acsDm as DeviceManager)
      const cameras = await dm.getCameras();
      const mics = await dm.getMicrophones();
      let speakers: AudioDeviceInfo[] = [];
      try {
        speakers = await dm.getSpeakers();
      } catch { } //eat the error, this fails on some browsers...

      if (props.defaultCameraLabel != '') {
        await dm.selectMicrophone(mics.filter(m => m.name == props.defaultMicrophoneLabel)[0]);
        if (speakers.length > 0) {
          await dm.selectSpeaker(speakers.filter(s => s.name == props.defaultSpeakerLabel)[0]);
        }
        console.log('Set camera to:');
        console.log(cameras.filter(c => c.name == props.defaultCameraLabel)[0]);
        await (dm as any).selectCamera(cameras.filter(c => c.name == props.defaultCameraLabel)[0]);
        //adapter?.startCamera();
      }
    }
  }

  useEffect(() => {
    adapter && setDevices();//async set and forget...
  }, [adapter, props.defaultCameraLabel, props.defaultMicrophoneLabel, props.defaultSpeakerLabel]);



  useEffect(() => {
    if (props.callIsReadyToStart && props.locator) {
      createAdapter({
        userId: props.userId,
        displayName: props.displayName,
        credential,
        locator: { chatThreadId: getChatThreadFromTeamsLink(props.locator.meetingLink), callLocator: { groupId: getChatThreadFromTeamsLink(props.locator.meetingLink) } },//props.locator,
        endpoint: props.endpointUrl,
        teamsLocator: props.locator,
      }).then(a => {
        setAdapter(a);
      })
    }
  }, [props.locator, props.callIsReadyToStart]);


  const memoizedOptions = useMemo(() => props.compositeOptions, []);

  // The adapter is created asynchronously by the useAzureCommunicationCallWithChatAdapter hook.
  // Here we show a spinner until the adapter has finished constructing.
  if (!adapter) {
    return <div style={{
      margin: '20px',
      minWidth: '250px',
      backgroundColor: 'white',
      borderRadius: '12px',
      padding: '60px', // Add some padding inside the card
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a shadow for better contrast
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Stack horizontalAlign="center" verticalAlign="center">
        <Spinner label={'Preparing your visit...'} />
      </Stack>
    </div>;
  }


  return (
    <>{adapter &&
      <div
        className='container'
      >
        {/* {props.locator && (props.locator as any).meetingLink && <HijackUI
          formFactor={props.formFactor}
          defaultCameraLabel={props.defaultCameraLabel}
          defaultMicrophoneLabel={props.defaultMicrophoneLabel}
          defaultSpeakerLabel={props.defaultSpeakerLabel}
        ></HijackUI>} */}
        <div className='top-bar'>
          <div>{props.logo && <img alt={props.logo.alt} src={props.logo.url} />}</div>
          <div><button className='thButton help'  aria-controls="helpText" aria-expanded={showHelpText} onClick={toggleHelpText}>{showHelpText ? 'Hide Support Information' : 'Need Help? Click Here'}</button></div>          
        </div>
        <div id="helpText" className='help-text' style={{maxHeight: showHelpText ? 60 : 0, padding: showHelpText ? 3 : 0, transition: 'max-height 0.3s ease, padding 0.3s ease'}}> If you're experiencing issues with audio or video during the call, please call customer care at <a tabIndex={-1} href="tel:+18779934321">1-877-993-4321</a> for assistance.</div>                       
        {showSurveyTitle && <div className='survey-title' ><h2>Share your Feedback</h2></div>}
        <div className='content-area'>        
          <CallWithChatComposite
            adapter={adapter}
            fluentTheme={props.fluentTheme}
            rtl={props.rtl}
            formFactor={props.formFactor}
            joinInvitationURL={props.callInvitationURL}
            options={memoizedOptions}
          />
        </div>
      </div>
    }
    </>
  );
};

export default CompositeGroupCall;