import * as React from 'react';
import { Button, Spinner, Stack } from '@fluentui/react';
import {tips} from '../constants/tips'
import { Tip } from 'constants/tips';
import './WaitingForProvider.css';

interface WaitingForProviderProps {
    backgroundImageUrl?: string; // New prop for background image URL
    logoUrl: string;
    label?: string;
    showTips?: boolean;
    showDeviceCheckOption?: boolean;
    showDeviceCheck?: () => void;
}

const WaitingForProvider: React.FC<WaitingForProviderProps> = ({ logoUrl, backgroundImageUrl, label="Waiting for Virtual Care Visit to start...", showTips=false, showDeviceCheckOption=false, showDeviceCheck }) => {
    const background = backgroundImageUrl ? `url(${backgroundImageUrl})` : '#FFFFFF00';
    const [tipIndex, setTipIndex] = React.useState(0);

    const advanceTipIndex = () => {
        setTipIndex((prevIndex) =>  (prevIndex + 1) % tips.length);
    };

    React.useEffect(() => {
        const intervalId = window.setInterval(advanceTipIndex, 7000);
        return ()=>{
            // console.log('clearing tip interval');
            window.clearInterval(intervalId);}
    }, []);

    return (
        // Fullscreen background container
        <div style={{
            position: 'fixed', // Use fixed to cover the entire screen
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: background,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        }}>
            {/* White card with rounded borders */}
            <div style={{
                margin: '10px',
                minWidth: '300px',
                backgroundColor: 'white',
                borderRadius: '12px',
                padding: '30px', // Add some padding inside the card
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a shadow for better contrast
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Stack horizontalAlign="center" verticalAlign="center">
                    <img alt='Company Logo' src={logoUrl} style={{ height: 24, margin:'auto', marginTop:0, marginBottom: 20 }} />
                    <Spinner label={label} />
                    {showTips &&
                        <div className={'tip-container'}>
                            {tips.map((t: Tip, index: number) => (
                                <div key={index} className={index == tipIndex ? 'active-tip' : (index == (tipIndex + 1) % tips.length ? 'next-tip': 'inactive-tip')} >Tip: {t.message}</div>
                            ))}
                        </div>
                    }
                    {showDeviceCheckOption && showDeviceCheck &&
                        <div style={{width: '100%'}}>
                            <Button className="thButton" onClick={showDeviceCheck}>Check my devices</Button>
                        </div>
                    }
                </Stack>
            </div>
        </div>
    );
};

export default WaitingForProvider;