import React, { useEffect, useState } from 'react';
import { Stack, Spinner, PrimaryButton, Text, DefaultButton, IDropdownOption } from '@fluentui/react';
import DevicePermissionsPrompt from './DevicePermissionsPrompt';
import DeviceSelection from './DeviceSelection';
import CameraCheck from './CameraCheck';
import MicrophoneCheck from './MicrophoneCheck';
import SpeakerCheck from './SpeakerCheck';
import ErrorMessage from './ErrorMessage';
import ProceedButton from './ProceedButton';
import TimedAnimationWrapper from './TimedAnimationWrapper';
import { TelehealthClient } from 'Utils/TelehealthClient';
import './CVS.css'
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';

interface DeviceCheckResult {
  camera: string;
  microphone: string;
  speaker: string;
  status: 'success' | 'failure';
}

interface DeviceCheckProps {
  onCheckComplete: (result: DeviceCheckResult) => void;
  onGetHelp: () => void;
  onTrackEvent: (name: string, eventMessage: string, properties?: any) => {};
  reportError: (exception: IExceptionTelemetry, customProperties?: {
    [key: string]: any;
  }) => {};
  backgroundImage?: string;
  logoUrl: string;
  telehealthClient: TelehealthClient;
  display: boolean;
}

const DeviceCheck: React.FC<DeviceCheckProps> = ({ onCheckComplete, onGetHelp, onTrackEvent, reportError, backgroundImage, logoUrl, telehealthClient, display }) => {
  const [devices, setDevices] = useState<{ cameras: MediaDeviceInfo[], microphones: MediaDeviceInfo[], speakers: MediaDeviceInfo[] }>({ cameras: [], microphones: [], speakers: [] });
  const [selectedCamera, setSelectedCamera] = useState<string | undefined>(undefined);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string | undefined>(undefined);
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | undefined>(undefined);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [micAccess, setMicAccess] = useState(false);
  const [displayPromptAgain, setdisplayPromptAgain] = useState(false);
  const [soundTested, setSoundTested] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [loading, setLoading] = useState(false);
  const [micCheckPassed, setMicCheckPassed] = useState(false);
  const [step, setStep] = useState(0);
  const [cameraCheckPassed, setCameraCheckPassed] = useState(false);
  const [allowDevicesHiding, setAllowDevicesHiding] = useState(false);
  const [cameraHiding, setCameraHiding] = useState(false);
  const [speakerHiding, setSpeakerHiding] = useState(false);
  const [micHiding, setMicHiding] = useState(false);

  const getDevices = async () => {

    // Request permissions for video and audio
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

    // Release the initial stream to free up resources
    await stream.getTracks().forEach(track => track.stop());

    //get devices - we should only get here if the media is allowed.
    const devices = await navigator.mediaDevices.enumerateDevices();

    const cameras = devices.filter(device => device.kind === 'videoinput' && device.label);
    const microphones = devices.filter(device => device.kind === 'audioinput' && device.label);
    const speakers = devices.filter(device => device.kind === 'audiooutput' && device.label);

    //these devices have Default - and Communication - in their names, we want to filter them and to eliminate duplicates
    //at the same time, we want to select whatever deviceID is matched to a device prefixed with Communication -

    //first lets eliminate duplicates by checking deviceId fields, prefferentially selecting the one with whose labels are prefixed with Communication -
    let selectPreferredDevices = (devices: MediaDeviceInfo[]) => {
      // Filter devices with labels starting with "Communication -"
      let preferredDevices = devices.filter(device => device.deviceId === 'communications');
      // If there are preferred devices, work with them; otherwise, use the original list
      let devicesToProcess = preferredDevices.length > 0 ? preferredDevices : devices;
      // Filter for unique devices based on deviceId
      return devicesToProcess;
    };

    let preferredCameras = selectPreferredDevices(cameras);
    let prefferedMicrophones = selectPreferredDevices(microphones);
    let preferredSpeakers = selectPreferredDevices(speakers);

    setDevices({ cameras, microphones, speakers });

    // Set default selected devices if there's only one option
    if (cameras.length) setSelectedCamera(preferredCameras[0].deviceId);
    if (microphones.length) setSelectedMicrophone(prefferedMicrophones[0].deviceId);
    if (speakers.length) setSelectedSpeaker(preferredSpeakers[0].deviceId);

  }

  const handleDeviceChange = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, type?: 'camera' | 'microphone' | 'speaker') => {
    let camera: string | null = null;
    let microphone: string | null = null;

    onTrackEvent("deviceCheckDeviceChanging", `${type} device changing to ${option?.text}`, { deviceType: type, deviceName: option?.text });

    if (type === 'camera') {
      if (option?.key !== selectedCamera) {
        setSelectedCamera(option?.key as string);
        setCameraCheckPassed(false);
        camera = option?.key as string;
      }
    }
    if (type === 'microphone') {
      if (option?.key !== selectedMicrophone) {
        setSelectedMicrophone(option?.key as string);
        setMicCheckPassed(false);
        microphone = option?.key as string;
      }
    }
    if (type === 'speaker') {
      if (option?.key !== selectedSpeaker) {
        setSelectedSpeaker(option?.key as string);
        setSoundTested(false);
      }
    }

    if (cameraStream) {
      await cameraStream.getTracks().forEach(track => track.stop());
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: camera || selectedCamera },
      audio: { deviceId: microphone || selectedMicrophone }
    })
    
    setCameraStream(stream);
    
    onTrackEvent("deviceCheckDeviceChanged", `${type} device changed to ${option?.text}`, { deviceType: type, deviceName: option?.text });

  };

  const startDeviceCheck = async () => {
    setErrorMessage('');
    setLoading(true);
    onTrackEvent("deviceCheckStarted", "Device check was started.");
    try {
      await getDevices();
      onTrackEvent("deviceCheckIDsRetrieved", "Device check retrieved deviceIDs.", {audioDeviceId: selectedMicrophone, videoDeviceId: selectedCamera});
      // Now that we have the device IDs, get the stream for the selected devices
      const selectedStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedCamera },
        audio: { deviceId: selectedMicrophone }
      });

      
      onTrackEvent("deviceCheckMediaAccessed", "Device check accessed camera and microphone.");

      // Use the selectedStream as needed, for example:
      setCameraAccess(true);
      setMicAccess(true);
      setCameraStream(selectedStream);

      setLoading(false);
      setAllowDevicesHiding(true);
      setStep(1); // Move to the next step after device check
    } catch (error) {
      setLoading(false);
      handleMediaError(error); // Handle errors, such as user denying permission
    }
  };

  const handleMediaError = async (error: any) => {
    let microphonePermissionState: PermissionState = (
      await navigator.permissions.query({ name: 'microphone' as PermissionName })
    ).state;

  
    let cameraPermissionState: PermissionState = (
      await navigator.permissions.query({ name: 'camera' as PermissionName })
    ).state;

    if(microphonePermissionState == 'prompt' || cameraPermissionState == 'prompt'){
      setdisplayPromptAgain(true);
    }
    else{
      setdisplayPromptAgain(false);
    }

    if (error.name === 'NotAllowedError') {
      setErrorMessage('Permissions to access camera and microphone are denied. Please enable permissions in your browser settings.');
      reportError(error, {source: 'deviceCheck', message: 'Permissions to access camera and microphone are required. Please enable permissions in your browser settings.'})
    } else if (error.name === 'NotFoundError') {
      setErrorMessage('No camera or microphone found. Camera or microphone are required.');
      reportError(error, {source: 'deviceCheck', message: 'No camera or microphone found. Camera or microphone are required.'})
    } else {
      setErrorMessage('Camera and microphone access is not available. Please ensure the camera and microphone are not already in use.');
      reportError(error, {source: 'deviceCheck', message: 'Camera and microphone access is not available. Please ensure the camera and microphone are not already in use.'})
    }
  };

  const handleSoundTest = (result: boolean) => {
    setSoundTested(result);
    if (result === true) {
      //setStep(4);
      setSpeakerHiding(true);
      setTimeout(() => {
        handleProceed(); //bypass step 4 window, it's not needed since we set defaults well now
      }, 300);
    }
  };

  const handleProceed = async () => {
    if (cameraStream) {
      await cameraStream.getTracks().forEach(track => track.stop());
    }
    
    onTrackEvent("deviceCheckComplete", "Device check was completed.");
    onCheckComplete({
      camera: stripExtraTextFromDeviceName(devices.cameras.find(device => device.deviceId === selectedCamera)?.label ?? ''),
      microphone: stripExtraTextFromDeviceName(devices.microphones.find(device => device.deviceId === selectedMicrophone)?.label ?? ''),
      speaker: stripExtraTextFromDeviceName(devices.speakers.find(device => device.deviceId === selectedSpeaker)?.label ?? ''),
      status: 'success'
    });
  };

  const handleRetry = () => {
    
    onTrackEvent("deviceCheckRetry", "Device check retry button pushed.");
    telehealthClient.postIncrementRetry(); //fire and forget...
    window.location.reload();
  };

  const stripExtraTextFromDeviceName = (name: string | undefined): string => {
    if (name === undefined) return '';
    return name.replace(/^Communications - |^Default - /, '');
  };

  function handleCameraCheck(result: boolean): void {
    setCameraHiding(true);
    onTrackEvent("deviceCheckCameraStepPassed", "Camera Step Complete.");
    cameraStream?.getVideoTracks().forEach(t => t.stop());
    setTimeout(() => {
      setCameraCheckPassed(result);
      setStep(2);
      //setCameraHiding(false);
    }, 500);
  }

  const handleFailure = () => {
    onTrackEvent("deviceCheckProblemsReported", "User clicked 'This isn't working'");
    telehealthClient.postHardwareTrouble();//fire and forget...
    onGetHelp();
  }

  useEffect(() => {
    startDeviceCheck();
  }, [])

  return (
    <div
      style={{
        backgroundImage: backgroundImage ? 'url(' + backgroundImage + ')' : '',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw',
        minWidth: '400px',
        display:  display ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1001',
        position: 'absolute',
      }}
    >

      <Stack tokens={{ childrenGap: 10 }} styles={{ root: { maxWidth: 350, width:'calc(100vw - 20px)', boxShadow: 'rgba(0, 0, 0, 0.7) 0px 8px 16px', position: 'relative', transition: 'min-height .3s ease-in-out', margin: '0 auto', marginBottom: '80px', textAlign: 'center', backgroundColor: 'white', borderRadius: '24px', padding: '10px 20px 10px 20px' } }}>
      <img alt='Company Logo' src={logoUrl} style={{ height: 24, margin:'auto', marginTop:7, marginBottom: 7 }} />
        <div style={{ maxWidth: '400x', marginTop: 5, marginBottom:0, textAlign: "left" }}>
        <Text variant="large" style={{fontWeight:700, fontSize: '24px'}}>Let's check your devices</Text>
        
        {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
        {step === 0 && (
          <TimedAnimationWrapper hidingItself={allowDevicesHiding}>
             {!loading && displayPromptAgain && <DevicePermissionsPrompt onPermissionsGranted={() => { startDeviceCheck(); }} />}
            {loading && 
            <div
              style={{
                padding: '60px',
              }
              }
            >
            <Spinner label="Checking devices..." />
            </div>}
          </TimedAnimationWrapper>
        )}
        {step === 1 && (
          <TimedAnimationWrapper hidingItself={cameraHiding}>
            <DeviceSelection
              devices={devices}
              selectedCamera={selectedCamera}
              selectedMicrophone={selectedMicrophone}
              selectedSpeaker={selectedSpeaker}
              onDeviceChange={handleDeviceChange}
              showOneDevice='camera'
            />
            <CameraCheck cameraStream={cameraStream} cameraAccess={cameraAccess} onCameraCheckPassed={handleCameraCheck} />
          </TimedAnimationWrapper>
        )}
        {step === 2 && (
          <TimedAnimationWrapper hidingItself={micHiding}>
            <DeviceSelection
              devices={devices}
              selectedCamera={selectedCamera}
              selectedMicrophone={selectedMicrophone}
              selectedSpeaker={selectedSpeaker}
              onDeviceChange={handleDeviceChange}
              showOneDevice='microphone'
            />
            <MicrophoneCheck
              micAccess={micAccess}
              audioStream={cameraStream}
              onMicCheckPass={() => { setMicHiding(true); setTimeout(() => { setMicCheckPassed(true); setStep(3);}, 500) }}
              onTrackEvent={onTrackEvent}
            />
          </TimedAnimationWrapper>
        )}
        {step === 3 && (

          <TimedAnimationWrapper hidingItself={speakerHiding}>
            <DeviceSelection
              devices={devices}
              selectedCamera={selectedCamera}
              selectedMicrophone={selectedMicrophone}
              selectedSpeaker={selectedSpeaker}
              onDeviceChange={handleDeviceChange}
              showOneDevice='speaker'
            />
            <SpeakerCheck soundTested={soundTested} onSoundTest={handleSoundTest} speakerDeviceId={selectedSpeaker} onTrackEvent={onTrackEvent} />
          </TimedAnimationWrapper>
        )}
        {step === 4 && (
          <>
            <div>You have passed the hardware device check and may proceed to your virtual care visit.</div>
            <div>
              <Text variant="large">Validated Devices:</Text><br />
              <div style={{ width: '100%vw', textAlign: 'left' }}>
                <Text variant="medium">Camera: {stripExtraTextFromDeviceName(devices.cameras.find(device => device.deviceId === selectedCamera)?.label)}</Text><br />
                <Text variant="medium">Microphone: {stripExtraTextFromDeviceName(devices.microphones.find(device => device.deviceId === selectedMicrophone)?.label)}</Text><br />
                <Text variant="medium">Speaker: {stripExtraTextFromDeviceName(devices.speakers.find(device => device.deviceId === selectedSpeaker)?.label)}</Text><br />
              </div>
            </div>
            <div>
              Be sure to select those devices in your virtual care visit if you enconter any issues with audio or video.
            </div>
            <ProceedButton
              canProceed={cameraCheckPassed && micCheckPassed && soundTested}
              onProceed={handleProceed}
            />
          </>
        )}
        <div style={{ borderTop: '1px solid lightgrey', paddingTop:-10 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <PrimaryButton className="thButton" onClick={handleRetry} text="Retry" disabled={loading} style={{ width: '100%' }} />
            </div>
            <div style={{ width: '16px' }} /> {/* Separation between buttons */}
            <div style={{ flex: 1 }}>
              <DefaultButton  className="thButton secondary" onClick={handleFailure} text="This isn't working" disabled={loading} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default DeviceCheck;
