// © Microsoft Corporation. All rights reserved.
import React, { useState, useEffect, Suspense} from 'react';
import { loadTheme, initializeIcons } from '@fluentui/react';
import { customTheme } from 'customTheme';
import { utils } from './Utils/Utils';
import { CommunicationUserIdentifier } from '@azure/communication-common';
import { CommunicationAccessToken } from '@azure/communication-identity';
import backgroundImage from './assets/desktop-background.svg';
import mobileBackgroundImage from './assets/mobile-background.svg';
import MCLogo from './assets/minuteclinic-logo.svg';
import CVSHealthLogo from './assets/cvs-logo.svg';
import DeviceCheck from 'components/DeviceCheck/DeviceCheck';
import WaitingForProvider from 'components/WaitingForProvider';
import './permissions-polyfill';
import CollectDisplayName from 'components/CollectDisplayName';
import { MeetingActivityStatus, MeetingStatus, TelehealthClient } from 'Utils/TelehealthClient';
import TelehealthCompletionScreen from 'components/TelehealthCompletionScreen';
import GlobalError from 'components/GlobalError';
import Help from 'components/Help';
import errorMessages from 'constants/errorMessages';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { configureAppInsights } from 'Utils/AppInsights';
import { getConfig } from 'config/appConfig';
import { CallSurvey, TeamsMeetingLinkLocator } from '@azure/communication-calling';
import { CallSurveyImprovementSuggestions } from '@azure/communication-react';
import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import {UAParser} from 'ua-parser-js';
import CompositeGroupCall from 'components/CompositeGroupCall';
// import {CompositeGroupCall} from './components/CompositeGroupCall';

loadTheme(customTheme);
initializeIcons();

const App = () => {
  //const [page, setPage] = useState('home');
  //const [callEndReason, setCallEndReason] = useState<CallEndReason | undefined>();
  const [meetingLink, setMeetingLink] = useState<String | null>(null);
  const [deviceCheckPassed, setDeviceCheckPassed] = useState(true);

  const [acsUser, setAcsUser] = useState<CommunicationUserIdentifier | null>(null);
  const [acsToken, setAcsToken] = useState<CommunicationAccessToken | null>(null);

  const [defaultCameraLabel, setDefaultCameraLabel] = useState('');
  const [defaultMicrophoneLabel, setDefaultMicrophoneLabel] = useState('');
  const [defaultSpeakerLabel, setDefaultSpeakerLabel] = useState('');
  const [nameSuffix, setnameSuffix] = useState('');
  const [acsEndPoint, setAcsEndPoint] = useState('');
  const [isProxyCaller, setIsProxyCaller] = useState(false);
  const [isInterpreter, setIsInterpreter] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [isAdHoc, setIsAdHoc] = useState(false);
  const [userDisplayName, setUserDisplayName] = useState<string | null>(null);
  const [telehealthClient, setTelehealthClient] = useState<TelehealthClient | null>(null);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState('');
  const [help, setHelp] = useState(false);
  const [errorIsRecoverable, setErrorIsRecoverable] = useState(false);
  const [brand, setBrand] = useState("CVSHealth");
  const [loadACSComponent, setLoadACSComponent] = useState(false);
  const [recoverableButtonText, setRecoverableButtonText] = useState('Try Again');
  // Correctly type the lazy-loaded component without directly importing the type
  // const CompositeGroupCall: React.LazyExoticComponent<React.ComponentType<any>> = lazy(() => import('./components/CompositeGroupCall'));
  const reactAppInsightsPlugin = new ReactPlugin();

  /*
  useEffect(() => {
    const setWindowWidth = () => {
      const width = typeof window !== 'undefined' ? window.innerWidth : 0;
      setScreenWidth(width);
    };
    setWindowWidth();
    window.addEventListener('resize', setWindowWidth);
    return () => window.removeEventListener('resize', setWindowWidth);
  }, []);
*/

  // useEffect
  // (
  //   () => {
  //     // Function to detect browser on mobile
  //     const detectUnsupportedBrowser = () => {          
  //       const isMobile = utils.isMobileDevice();         
  //       console.log(isMobile) ;
  //       if (isMobile &&  utils.isUnsupportedBrowser()) {
  //         setIsUnsupportedBrowser(true);
  //         setError(errorMessages.BrowerCompatabilityError);
  //         setErrorIsRecoverable(false)            
  //       }
  //     };
  //     detectUnsupportedBrowser();
  //   }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const acsInfo = await telehealthClient?.getACSToken();
        if (acsInfo) {
          setAcsEndPoint(acsInfo.acsUri);
          setAcsUser({ communicationUserId: acsInfo.user.id });
          setAcsToken(acsInfo.token);
          setTimeout(() => setLoadACSComponent(true), 1000);
        }
      } catch (e: any) {
        if (e.message == 'Failed to fetch') {
          registerError(errorMessages.FailedtofetchError, true, e);
        } else {
          registerError(errorMessages.GenericFetchError, true, e);
        }
      }
    };

    telehealthClient && fetchToken().then(() => setBrand(telehealthClient.TokenClaims?.Brand ?? brand));
  }, [telehealthClient]);

  async function registerError(errorMsg: string, isRecoverable: boolean, innerException?: Error, recoverableButtonTextValue?: string) {
    setError(errorMsg);
    setErrorIsRecoverable(isRecoverable);
    setRecoverableButtonText(recoverableButtonTextValue ?? 'Try Again');
    // await loadAppInsights(); //just in case this is before it's loaded...
    let message = errorMsg;
    if (innerException) {
      message += "\n\nInner Exception: " + (innerException.name ?? "") + ":\n" + (innerException.message ?? "") + "\n" + innerException.stack ?? "";
    }
    try {
      await reportAIError({ error: new Error(message) }); //this function is always fire and forget, but we await internally....
    } catch (e) {
      console.error('Error tracking to app insights. exception: ' + message);
      console.error(e);
    }
  }

  async function reportAIError(exception: IExceptionTelemetry, customProperties?: {
    [key: string]: any;
  }) {


    try {
      if (reactAppInsightsPlugin.isInitialized() == false) {
        await loadAppInsights();
      }
      reactAppInsightsPlugin?.trackException(exception, {...customProperties,  origin: window.location.href, userAgent: navigator?.userAgent });
    } catch { }//eat it
  }

  async function reportAIEvent(event: IEventTelemetry, customProperties?: {
    [key: string]: any;
  }) {
    try {
      if (reactAppInsightsPlugin.isInitialized() == false) {
        await loadAppInsights();
      }
      reactAppInsightsPlugin?.trackEvent(event, {...customProperties,  origin: window.location.href, userAgent: navigator?.userAgent });
    } catch { } //eat it
  }

  async function loadBrand() {
    try {
      const cli = new TelehealthClient();
      const brand = await cli.getBrandFromCode(utils.userCode.substr(0, 25));
      setBrand(brand);
    } catch (e: any) {
      if (e.message.indexOf("Meeting Time has Expired") >= 0) {
        registerError(errorMessages.VisitCompleteError, false, e);
      } else if (e.message.indexOf("Code not found") >= 0) {
        registerError(errorMessages.InvalidVisitError, false, e);
      } else {
        registerError(errorMessages.GenericFetchError, true, e);
      }

    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('App is hidden');
        // Report the event here
        reportAIEvent({ name: 'User has moved away'});
      } else {
        console.log('App is visible again');
        reportAIEvent({ name: 'User has came back'});
        // Report the event here
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    async function loadMeeting() {
      await loadAppInsights();
      let browser: any;
      
      try{
        const parser = new  UAParser();
        browser = parser.getResult();
      }catch{
        browser = "UA parser fails.";
      } //eat any error here

      reportAIEvent({ name: 'App Loaded', properties: { browserInfo: browser }});

     if(navigator.userAgent == 'Mozilla/5.0 (Linux; Android 7.0; SM-G930V Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36 (compatible; Google-Read-Aloud; +https://support.google.com/web' ||
     (navigator.userAgent.indexOf('Mobile') > 0 && navigator.userAgent.indexOf('Firefox') > 0)){
        registerError(errorMessages.BrowerCompatabilityError, false);
      }

      let code = "";
      let visitorTypeCode = "";
      try {
        code = window.location.pathname.replace(/\/+$/, '').split('/').pop() || "";
        if (code?.length > 25) {
          visitorTypeCode = code[25].toString();
        }
      } catch (e) {
        code = "";
      }

      if (code == '') {
        registerError(errorMessages.InvalidVisitError, false, new Error('User code empty or invalid.'));
        return;
      }

      if (visitorTypeCode == "P") {
        loadBrand().then(() => {
          setIsProxyCaller(true);
          setnameSuffix(' - Guest');
        });
      } else if (visitorTypeCode == "A") {
        loadBrand().then(() => {
          setIsAdHoc(true);
        });
      } else if (visitorTypeCode == "I") {
        loadBrand().then(() => {
          setIsInterpreter(true);
          setnameSuffix(' - Interpreter');
          setDeviceCheckPassed(true);//interpreter does not need to pass device check, they are already vetted by the provider
        });
      } else {
        setIsPatient(true);
        const cli = new TelehealthClient();
        const fetchToken = async () => {
          try {
            await cli.initializeToken(utils.userCode, utils.isTouchDevice, "");
            setUserDisplayName(cli.TokenClaims?.DisplayName + ' - Patient');            
            setTelehealthClient(cli);
          } catch (e: any) {
            if (e.message.indexOf("Meeting Time has Expired") >= 0) {
              registerError(errorMessages.VisitCompleteError, false, e);
            } else if (e.message.indexOf("Code not found") >= 0) {
              registerError(errorMessages.InvalidVisitError, false, e);
            } else {
              registerError(errorMessages.GenericFetchError, true, e);
            }

          }
        };
        fetchToken();//fire and forget from the non async function

      }
    }

    loadMeeting();
  }, []);

  useEffect(() => {
    const fetchLink = async () => {
      let link: string | null = null;
      let timeOut: Date;
      const waitingMinutes = 30;
      // console.log("appointment scheduled start time:" + telehealthClient?.TokenClaims?.ScheduledStartTime);
      timeOut = new Date(new Date().getTime() + (waitingMinutes * 60 * 1000));
      //check if scheduled date is greater than current time
      if (telehealthClient?.TokenClaims?.ScheduledStartTime && new Date(telehealthClient.TokenClaims?.ScheduledStartTime) > (new Date())) {
        timeOut = new Date(new Date(telehealthClient.TokenClaims?.ScheduledStartTime).getTime() + (waitingMinutes * 60 * 1000));
      }

      let timeElapsed = 0;
      do {
        try {
          link = await telehealthClient?.getTeamsMeetingLink() || null;
        } catch (error) {
          console.error('Error fetching link:', error);
          await new Promise(resolve => setTimeout(resolve, 5000));
          timeElapsed += 5000;
          const waitTime = timeOut.getTime() - new Date().getTime();
          if (waitTime < 0) {
            break;
          }
        }
      } while (!link);

      if (!link) {
        //timeout elapsed, cancel visit
        registerError("We apologize, but it appears your provider has failed to join your Virtual Care Visit and is unlikely to join at this time. Please schedule a new visit at your earliest convenience.", false, new Error('No link found after timeout.'));
      }
      else{
        (isPatient || isAdHoc) && await telehealthClient?.postMeetingStatus({ Status: "MeetingStatus:" + MeetingStatus.PatientReceivedMeetingLink + "|MeetingActivityStatus:"+ MeetingActivityStatus.PatientReceivedMeetingLink});
        reportAIEvent({ name: 'Meeting link received', properties: { isPatient, isAdHoc, isInterpreter } });
        setMeetingLink(link);
      }
    };

    telehealthClient && fetchLink();
  }, [telehealthClient]);
  
  let appInsights: ApplicationInsights;

  async function loadAppInsights(): Promise<void> {

    let config = await getConfig();
    if (!appInsights) {
      appInsights = new ApplicationInsights({
        config: {
          connectionString: config.AIConnectionString,
          extensions: [reactAppInsightsPlugin]
        }
      });
      appInsights.loadAppInsights();
    }
    return new Promise(resolve => {
      let count = 0;
      let inter = window.setInterval(function () {
        count += 100;
        if (count > 2000) {
          resolve();
        }
        if (reactAppInsightsPlugin.isInitialized()) {
          window.clearInterval(inter);          
          resolve();
        }
      }, 100);
    });
  }

  const getContent = () => {
    const formFactor = utils.isMobileDevice() ? 'mobile' : 'desktop';
    const backgroundImage2 = formFactor == 'desktop' ? backgroundImage : mobileBackgroundImage;
    if(brand == 'MinuteClinic' && document.title != 'Minute Clinic Virtual Care'){
      document.title = 'Minute Clinic Virtual Care';
    }
    if (error.length > 0) {
      return <GlobalError isRecoverable={errorIsRecoverable} message={error} backgroundImage={backgroundImage2} logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo} onClose={() => {setError(''); window.location.reload();}} recoverableButtonText={recoverableButtonText} ></GlobalError>
    }

    if (help) {
      return <Help message="Select the camera icon by your address bar and then 'Always allow'."></Help>
    }

    if (!acsToken && !isProxyCaller && !isInterpreter && !isAdHoc) {
      return <WaitingForProvider  logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo} label={'Connecting...'} backgroundImageUrl={backgroundImage2} />
    }

    if ((isProxyCaller || isInterpreter || isAdHoc) && !userDisplayName) {
      return <CollectDisplayName nameSubmitted={async (name) => {
        try {
          const cli = new TelehealthClient();
          await cli.initializeToken(utils.userCode, utils.isTouchDevice, name)
          setTelehealthClient(cli);
          setUserDisplayName(name + nameSuffix);
        } catch (e: any) {
          if (e.message.indexOf("Meeting Time has Expired") >= 0) {
            registerError(errorMessages.VisitCompleteError, false, e);
          } else if (e.message.indexOf("Code not found") >= 0) {
            registerError(errorMessages.InvalidVisitError, false, e);
          } else {
            registerError(errorMessages.GenericFetchError, true, e);
          }
        }


      }} backgroundUrl={backgroundImage2} logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo} />
    } else {
      return <div
        style={{
          backgroundImage: 'url(' + backgroundImage2 + ')',
          backgroundSize: 'cover',
          height: '100%',
          minHeight: '100%',
          width: '100%',
          minWidth: '300px',
          display: 'flex',
          position: 'absolute',
          top: '0px',
          left: '0px',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        {!deviceCheckPassed &&
          <DeviceCheck
            display={(!isComplete && !deviceCheckPassed)}
            onGetHelp={() => setHelp(true)}
            logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo}
            telehealthClient={telehealthClient as TelehealthClient}
            onTrackEvent={async (name: string, eventMessage: string, properties?: any) => {
              reportAIEvent({ name: name, properties: {...properties, description: eventMessage, source: 'deviceCheck'} });
            }}
            reportError={reportAIError}
            onCheckComplete={async (result) => {
              setDefaultCameraLabel(result.camera);
              setDefaultMicrophoneLabel(result.microphone);
              setDefaultSpeakerLabel(result.speaker);
              setDeviceCheckPassed(result.status === 'success');
              telehealthClient?.postDeviceCheckComplete(); //fire and forget on purpose...
              (isPatient || isAdHoc) && await telehealthClient?.postMeetingStatus({ Status: MeetingStatus.Waiting })
            }} />
        }

        {(!isComplete && (deviceCheckPassed) && (!acsUser || !meetingLink)) &&
          <WaitingForProvider logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo} showTips={true} showDeviceCheck={() => {setDeviceCheckPassed(false);}} showDeviceCheckOption={true} />
        }

        {loadACSComponent && !isComplete && acsToken && meetingLink && <Suspense fallback={<WaitingForProvider  logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo} backgroundImageUrl={backgroundImage2} />}>
          <CompositeGroupCall
          userTokenCode = {utils?.userCode?.substring(0, 25)}
            autoJoin={false}
            logo={{
              alt: brand == 'CVSHealth' ? 'CVS Health' : 'Minute Clinic',
              url: brand == 'CVSHealth' ? CVSHealthLogo : MCLogo //'https://www.cvshealth.com/content/dam/enterprise/cvs-enterprise/media-library/logos/migratedcontent/minuteclinic-logo.png' 
            }}
            onGlobalError={(strErr: string, isRecoverable: boolean, ex: any) => {
              return registerError(strErr, isRecoverable, ex);
            }}
            onTrackEvent={async (name: string, eventMessage: string, properties?: any) => {
              reportAIEvent({ name: name, properties: {...properties, description: eventMessage, source: 'compositeGroupCall'} });
            }}
            key={1}
            fluentTheme={customTheme}
            defaultCameraLabel={defaultCameraLabel}
            defaultMicrophoneLabel={defaultMicrophoneLabel}
            defaultSpeakerLabel={defaultSpeakerLabel}
            formFactor={formFactor}
            callIsReadyToStart={deviceCheckPassed}
            telehealthClient={telehealthClient as TelehealthClient}
            compositeOptions={
              {
                remoteVideoTileMenuOptions: {isHidden: true},
                callControls: {
                  endCallButton: false,
                  dtmfDialerButton: false,
                  raiseHandButton: false,
                  moreButton: true,
                  reactionButton: false,
                  galleryControlsButton: false,
                  exitSpotlightButton: false,
                  captionsButton: false,
                  //microphoneButton: false,
                  //cameraButton: false,
                },
                spotlight: {
                  hideSpotlightButtons: true,
                },
                joinCallOptions:{
                  microphoneCheck: 'skip'
                },
                branding: {
                  backgroundImage: {
                    url: backgroundImage2//'https://www.cvshealth.com/content/dam/enterprise/cvs-enterprise/media-library/images/migratedcontent/cvs-pharmacy-healthhub-01.jpg'
                  }
                },
                surveyOptions: {
                  disableSurvey: false,
                  onSurveyClosed: async (surveyState: 'sent' | 'skipped' | 'error', surveyError?: string) => {
                    //show provider rating?
                    //alert('survey closed');


                    completeVisit(telehealthClient, setIsComplete);
                    reportAIEvent({ name: 'Survey Closed', properties: { surveyState, userAgent: navigator?.userAgent } });
                  },
                  onSurveySubmitted: async (callId: string, surveyId: string, submittedSurvey: CallSurvey, improvementSuggestions: CallSurveyImprovementSuggestions) => {
                    console.log(submittedSurvey);
                    if (submittedSurvey.overallRating?.score) {
                      let disDeats = [
                        ...submittedSurvey.audioRating?.issues?.map(i => {
                          return {
                            DetailType: 'Audio',
                            Detail: i,
                            isFreeFormText: false
                          };
                        }
                        ) || [],
                        ...submittedSurvey.videoRating?.issues?.map(i => {
                          return {
                            DetailType: 'Video',
                            Detail: i,
                            isFreeFormText: false
                          };
                        }
                        ) || [],
                        ...submittedSurvey.overallRating?.issues?.map(i => {
                          return {
                            DetailType: 'Platform',
                            Detail: i,
                            isFreeFormText: false
                          };
                        }
                        ) || [],
                        ...submittedSurvey.screenshareRating?.issues?.map(i => {
                          return {
                            DetailType: 'Platform',
                            Detail: i,
                            isFreeFormText: false
                          };
                        }
                        ) || [],
                        ...[{
                          DetailType: 'Audio',
                          Detail: improvementSuggestions.audioRating,
                          isFreeFormText: true
                        }],
                        ...[{
                          DetailType: 'Video',
                          Detail: improvementSuggestions.videoRating,
                          isFreeFormText: true
                        }],
                        ...[{
                          DetailType: 'Platform',
                          Detail: improvementSuggestions.overallRating,
                          isFreeFormText: true
                        }],
                        ...[{
                          DetailType: 'Platform',
                          Detail: improvementSuggestions.screenshareRating,
                          isFreeFormText: true
                        }],
                      ];
                      disDeats = disDeats.filter(d => d.Detail != null && d.Detail != '');

                      let surveyTitle = (submittedSurvey.overallRating?.score < 5) ? 'Poor' : 'Good';
                      reportAIEvent({ name: surveyTitle + ' Survey Submitted', properties: { stars: submittedSurvey.overallRating?.score, userAgent: navigator?.userAgent } });

                      return telehealthClient?.saveMeetingRating({ MeetingRating: { PlatformStars: submittedSurvey.overallRating?.score }, DissatisfactionDetails: disDeats });
                    }
                  }

                }
              }}
            locator={{ meetingLink: meetingLink } as TeamsMeetingLinkLocator}
            userId={acsUser as CommunicationUserIdentifier}
            token={acsToken.token}
            displayName={userDisplayName as string}
            endpointUrl={acsEndPoint}
            onCallComplete={() => {
              completeVisit(telehealthClient, setIsComplete);
            }
            }
          />
        </Suspense>}
        {isComplete && <TelehealthCompletionScreen backgroundUrl={backgroundImage2} logoUrl={brand == 'CVSHealth' ? CVSHealthLogo : MCLogo} />}
      </div>;
    }
  };

  if (reactAppInsightsPlugin) {
    return <AppInsightsContext.Provider value={reactAppInsightsPlugin}> {getContent()} </AppInsightsContext.Provider>
  } else {
    return getContent();
  }
};

export default App;
function completeVisit(telehealthClient: TelehealthClient | null, setIsComplete: React.Dispatch<React.SetStateAction<boolean>>) {
  if (telehealthClient?.TokenClaims?.CallbackUri != null && telehealthClient?.TokenClaims?.CallbackUri.length > 0) {
    let sep = "?=";
    if (telehealthClient?.TokenClaims?.CallbackUri.indexOf("?=") > 0) {
      sep = "&";
    }
    window.location.href = telehealthClient?.TokenClaims?.CallbackUri + sep + "status=CallEnded";
  } else {
    setIsComplete(true);
  }
}

