import React from 'react';
import { Stack } from '@fluentui/react';
import ErrorMessage from './DeviceCheck/ErrorMessage';


const GlobalError: React.FC<{ isRecoverable: boolean, message: string, logoUrl: string, backgroundImage: string, onClose: () => void, recoverableButtonText?: string }> = ({ isRecoverable, message, logoUrl, backgroundImage, onClose, recoverableButtonText }) => {


    return (
        <div
            style={{
                backgroundImage: backgroundImage ? 'url(' + backgroundImage + ')' : '',
                backgroundSize: 'cover',
                height: '100vh',
                width: '100vw',
                minWidth: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '1001',
                position: 'absolute',
            }}
        >

            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { boxShadow: 'rgba(0, 0, 0, 0.7) 0px 8px 16px', position: 'relative', minWidth: '300px',maxWidth: '600px', minHeight: '200px', transition: 'min-height .3s ease-in-out', margin: '0 auto', marginBottom: '80px', marginLeft:20, marginRight:20, textAlign: 'center', backgroundColor: 'white', borderRadius: '24px', padding: '10px 20px 10px 20px' } }}>
                <img alt='Company Logo' src={logoUrl} style={{ height: 24, margin: 'auto', marginTop: 7, marginBottom: 7 }} />
                <div style={{ marginTop: -17, marginBottom: 0, textAlign: "left" }}>
                    {message && <ErrorMessage message={message} />}
                    <div style={{ textAlign: 'center' }}>
                        {isRecoverable && (
                            <button
                                onClick={onClose}
                                style={{ width: '200px' }}
                                className='thButton'
                            >
                                {recoverableButtonText || 'Try Again'}
                            </button>
                        )}
                    </div>
                </div>
            </Stack>
        </div>
    );
};


export default GlobalError;