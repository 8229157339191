import { IncomingAudioMediaStats, IncomingVideoMediaStats, MediaStatsReportSummary, MediaStatValue, OutgoingAudioMediaStats, OutgoingVideoMediaStats } from "@azure/communication-calling";

declare interface CommonStats<NumberType, StringType> {
    id: string,
    bitrate?: NumberType;
    jitterInMs?: NumberType;
    packets?: NumberType;
    packetsPerSecond?: NumberType;
    packetsLost?: NumberType;
    packetsLostPerSecond?: NumberType;
    rttInMs?: NumberType;
}

declare interface VideoSummarySection {
    send: OutgoingVideoMediaStats<MediaStatValue<number>, MediaStatValue<string>>[],
    receive: IncomingVideoMediaStats<MediaStatValue<number>, MediaStatValue<string>>[]
}

declare interface AudioSummarySection {
    send: OutgoingAudioMediaStats<MediaStatValue<number>, MediaStatValue<string>>[],
    receive: IncomingAudioMediaStats<MediaStatValue<number>, MediaStatValue<string>>[]
}

export default class MetricsUtils {

    private mergeMetrics(metric1?: MediaStatValue<number>, metric2?: MediaStatValue<number>): MediaStatValue<number> | undefined {

        if (metric1 && !metric2) {
            return metric1;
        }

        if (metric2 && !metric1) {
            return metric2;
        }

        
        if (metric1 && metric2) {
            return {
                raw: metric1.raw.concat(metric2.raw),
                timestamp: metric1.timestamp,
                aggregation: {
                    count: [metric1.raw.length + metric2.raw.length],
                    sum: [metric1.raw.reduce((a, b) => a + b, 0) + metric2.raw.reduce((a, b) => a + b, 0)],
                    max: [Math.max(...metric1.raw, ...metric2.raw)],
                    min: [Math.min(...metric1.raw, ...metric2.raw)]
                }
            }
        }

        return metric1;
    }

    private mergeAudioStreams(stream1: CommonStats<MediaStatValue<number>, MediaStatValue<string>>, stream2: CommonStats<MediaStatValue<number>, MediaStatValue<string>>): CommonStats<MediaStatValue<number>, MediaStatValue<string>> {
        return {
            /*codecName: {
              raw: stream1.codecName.raw.concat(stream2.codecName.raw),
              timestamp: stream1.codecName.timestamp // Assuming same timestamp for simplicity
            },*/
            ...stream1,
            id: stream1.id,
            bitrate: this.mergeMetrics(stream1.bitrate, stream2.bitrate),
            packets: this.mergeMetrics(stream1.packets, stream2.packets),
            packetsPerSecond: this.mergeMetrics(stream1.packetsPerSecond, stream2.packetsPerSecond),
            packetsLost: this.mergeMetrics(stream1.packetsLost, stream2.packetsLost),
            packetsLostPerSecond: this.mergeMetrics(stream1.packetsLostPerSecond, stream2.packetsLostPerSecond),
            jitterInMs: this.mergeMetrics(stream1.jitterInMs, stream2.jitterInMs),
            rttInMs: this.mergeMetrics(stream1.rttInMs, stream2.rttInMs),
        };
    }

    private mergeSections(audio1: AudioSummarySection | VideoSummarySection, audio2: AudioSummarySection | VideoSummarySection): AudioSummarySection | VideoSummarySection {
        const mergedAudio: AudioSummarySection | VideoSummarySection = {
            send: [],
            receive: []
        };

        // Merging send streams
        for (let i = 0; i < audio1.send.length; i++) {
            mergedAudio.send.push(this.mergeAudioStreams(audio1.send[i], audio2.send[i]));
        }

        // Merging receive streams
        for (let i = 0; i < audio1.receive.length; i++) {
            mergedAudio.receive.push(this.mergeAudioStreams(audio1.receive[i], audio2.receive[i]));
        }

        return mergedAudio;
    }



    public mergeMetricSummary(summary1: MediaStatsReportSummary | null, summary2: MediaStatsReportSummary): MediaStatsReportSummary {
        
        if (summary1 == null){
            return summary2;
        }
        return { ...summary2, audio: this.mergeSections(summary1.audio, summary2.audio), video: this.mergeSections(summary1.video, summary2.video) }
    }
}